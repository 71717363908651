<template>
<div class="log">
  <el-table :data="tableData" style="width: 100%" max-height="740" :row-class-name="tableRowClassName" @row-click="clickData" v-if="equipment != '移动'">
    <el-table-column
      prop="from"
      label="From"
      width="160"
      class-name="custom-first-column"
      >
    </el-table-column>
    <el-table-column
      prop="to"
      label="To"
      width="150">
    </el-table-column>
	<el-table-column
	   prop="duration"
	   label="通话时长"
	   width="100">
	 </el-table-column>
   <el-table-column
      prop="ans_time"
      label="接听开始时间(中国)"
      width="200">
    </el-table-column>
    <el-table-column
      prop="conversionEnd_time"
      label="挂断/结束时间(中国)"
      width="200">
    </el-table-column>
    <el-table-column
      prop="status"
      label="状态"
      width="120">
    </el-table-column>
  </el-table>
  
   <div class="box_box">
	<el-table :data="tableData"  :row-class-name="tableRowClassName" @row-click="clickData" v-if="equipment == '移动'"> 
		<el-table-column
			prop="from"
			label="呼入号码"
			:width="numberwidth"
			class-name="custom-first-column"
			>
		  </el-table-column>
		<el-table-column
		  prop="to"
		  label="接收号码"
		  :width="numberwidth"
		  class-name="custom-first-column"
		  >
		</el-table-column>
		 <el-table-column
			prop="duration"
			label="通话时长"
			:width="timewidth"
		  >
		  </el-table-column>
	</el-table>
   </div>
	  
  <div class="box" v-if="detailed_show" @click="hidde">
	  <div class="box_con">
		  <div class="title">详 情</div>
		  <div class="con_vo">
			<div class="con">
			 <div v-if="detailed.id"><span>id：</span><span>{{detailed.id}}</span></div>
			 <div  v-if="detailed.duration"><span>通话时长：</span><span>{{detailed.duration}}</span></div>
			 <div :class="detailed.status === 'unanswered' ? 'unanswered' : (detailed.status === 'rejected' ? 'rejected': '')"><span>通话状态：</span><span>{{detailed.status}}</span></div>
			 <div v-if="detailed.from"><span>呼入号码(from)：</span><span>{{detailed.from}}</span></div>
			 <div v-if="detailed.to"><span>接收号码(to)：</span><span>{{detailed.to}}</span></div>
			 <div v-if="detailed.to_user"><span>接收账号(to_user)：</span><span>{{detailed.to_user}}</span></div>
			 <div class="LineFeed"><span>接听开始时间(中国)：</span><span>{{detailed.ans_time}}</span></div>
			 <div v-if="detailed.conversionStart_time" class="LineFeed"><span>拨打开始时间(中国)：</span><span>{{detailed.conversionStart_time}}</span></div>
			 <div v-if="detailed.conversionEnd_time" class="LineFeed"><span>挂断/结束时间(中国)：</span><span>{{detailed.conversionEnd_time}}</span></div>
			 <div v-if="detailed.start_time" class="LineFeed"><span>拨打开始时间(美国)：</span><span>{{detailed.start_time}}</span></div>
			 <div v-if="detailed.end_time" class="LineFeed"><span>挂断/结束时间(美国)：</span><span>{{detailed.end_time}}</span></div>
			 <!-- <div v-if="detailed.from_user"><span>呼入账号(from_user)：</span><span>{{detailed.from_user}}</span></div> -->
			 <!-- <div v-if="detailed.conversation_uuid" class="LineFeed"><span>通话记录id：</span><span>{{detailed.conversation_uuid}}</span></div> -->
			 <!-- <div v-if="detailed.from_uuid"><span>呼入uuid：</span><span>{{detailed.from_uuid}}</span></div>
			 <div v-if="detailed.to_uuid"><span>接收uuid：</span><span>{{detailed.to_uuid}}</span></div> -->
			</div>
		  </div>
	  </div>
  </div>
  
 <div class="log-pagination">
    <el-pagination
    background
    layout="sizes, prev, pager, next"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page.sync="listPage.page"
    :page-sizes="[100, 200, 300, 400]"
    :page-size="listPage.limits"
    :total="total"
    >
	</el-pagination>
  </div>
  
</div>
</template>
c
<script>
import { getLogs } from '@/api/log.js'
import moment from 'moment'
export default {
	data() {
	  return {
	    listPage: {
	      page: 1,
	      limits: 100
	    },
	    total: 0,
	    tableData: [],
		detailed:[],
		detailed_show:false,
		equipment:'',
		numberwidth:'',
		timewidth:''
	  }
	},
	
    created() {
      this.getList()
	  // 判断设备
	  if(document.documentElement.clientWidth < 640){
		  this.equipment = "移动"
	  }else if(document.documentElement.clientWidth > 640 && document.documentElement.clientWidth < 999){
		  this.equipment = "iPad"
	  }else{
		  this.equipment = "pc"
	  }
	  
	  this.numberwidth = (document.documentElement.clientWidth/10) *3.5 + 'px'
	  this.timewidth = (document.documentElement.clientWidth/10) *3 + 'px'
    },
	watch:{
	  $route(to){
		  if(to.path == '/log'){
			this.getList()
			this.detailed_show = false
		  }
	  }
	},
    methods: {
	// 查看详情
	clickData(row, event, column) {
		this.detailed = row
		this.detailed_show = true
	}, 
	 // 关闭详情
	hidde(){
	 	this.detailed_show = false
	 },
	// 转化为中国开始时间
	conversion_start_time(start_time) {
		//获取本地时区，判断如果是负的则相加得到GMC时间，正的则相减
		var localUtc = new Date().getTimezoneOffset() / 60;
		var utcTime = moment(start_time).unix() * 1000
		
		//得到时区的绝对值
		var localTime = utcTime + 3600000 * Math.abs(localUtc);
		let date = new Date(localTime);
		var start_time = moment(date).format('YYYY-MM-DD HH:mm:ss')
		return start_time;
	},	
	// 转化中国结束时间
	conversion_end_time(end_time) {
		//获取本地时区，判断如果是负的则相加得到GMC时间，正的则相减
		var localUtc = new Date().getTimezoneOffset() / 60;
		var utcTime = moment(end_time).unix() * 1000
		//得到时区的绝对值
		var localTime = utcTime + 3600000 * Math.abs(localUtc);
		let date = new Date(localTime);
		var end_time = moment(date).format('YYYY-MM-DD HH:mm:ss')
		return end_time;
	},
	// 计算通话时长  计算通话时长 = 结束时间 - 接听开始时间
	cumputedBeginEndTime(dateBegin,dateEnd){
	    //如果传的是2020-12-4 12:23:12这样的格式算时间，先把-都转化为/.然后再计算
		dateBegin = new Date(dateBegin.replace(/-/g, '/'));//将-转化为/，使用new Date
		dateEnd = new Date(dateEnd.replace(/-/g, '/'));//将-转化为/，使用new Date
		dateBegin = new Date(dateBegin);//获取时间毫秒值
		dateEnd = new Date(dateEnd);//获取时间毫秒值
		dateBegin = dateBegin.getTime();//获取时间毫秒值
		dateEnd = dateEnd.getTime();//获取时间毫秒值
		let dateDiff = dateEnd - dateBegin;
		var hour=Math.floor(dateDiff/1000/60/60);
		var minute=Math.floor(dateDiff/1000/60-hour*60);
		var second=dateDiff/1000-hour*60*60-minute*60;
		if(hour < 10){hour = "0" + hour}
		if(minute < 10){minute = "0" + minute}
		if(second < 10){second = "0" + second}
		let retureTime = hour+":"+minute+":"+second+""
		return retureTime;
	},
	// 没登录的情况下，记录状态为空，因为没有登录获取不到此记录状态，使用逻辑来说这个应该就是未接听的状态 
	when_not_logged_in(status){
		var status
	    if(status == null || status == ''){status = "unanswered"}
		else{status = status}
		return status;
	},
	// 分页
    handleSizeChange(size) {
      this.listPage.limits = size
      this.getList(this.listPage)
    },
	// 分页条数
    handleCurrentChange() {
      this.getList(this.listPage)
    },
    getList() {
		getLogs(this.listPage).then(res => {
			var tableData = res.data.data
			// 将原有的时间改成中国时间
			tableData.forEach(e =>{
				if(e.start_time != null && e.end_time != null){
					e.conversionStart_time = this.conversion_start_time(e.start_time)
					e.conversionEnd_time = this.conversion_start_time(e.end_time)
					e.status = this.when_not_logged_in(e.status)
				}
				if(e.ans_time != null && e.conversionEnd_time != null){
					e.duration = this.cumputedBeginEndTime(e.ans_time,e.conversionEnd_time)
				}else{
					e.duration = ''
				}
				
			})
			this.tableData = tableData
		    this.total = res.data.total
		})
    },
	// 判断通话状态获取颜色
	tableRowClassName(row){
		if(row.row.status == "unanswered"){
			return 'rowstyle'
		}
		if(row.row.status == "rejected"){
			return 'rowstylerejected'
		}
	}
  }
}
</script>

<style lang="scss" scoped>
 ::v-deep .is-scrolling-none::-webkit-scrollbar {
    display: none;
}
::v-deep.el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
}
::v-deep.el-table--scrollable-x ::-webkit-scrollbar {
    display: none;
}
.rejected{
	color: blue;
}
.unanswered{
	color: red;
}
@media screen and (min-width: 1000px){
  .log{
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #fff;
    ::v-deep .custom-first-column{
      padding-left: 20px;
    }
    .log-pagination{
      position: absolute;
      width: 100%;
	  background-color: #fff;
      bottom: 0;
      padding: 14px 0;
      text-align: center;
    }
	
  }
    ::v-deep .el-table .rowstyle{
  		color:red;
  	}
	::v-deep .el-table .sortTableHeight{
		border: #333fff solid 1px;
	}
	::v-deep .el-table .rowstylerejected{
		color:#333fff;
	}
	.box{
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		background-color:rgba(0,0,0,0.2);
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #606662;
	}
	.box_con{
		background-color: #fff;
		margin: 0 auto;
		width: 90%;
		position: relative;
	}
	.box_con .con{
		padding: 10px 20px;
		border-bottom: 1px solid #EBEEF5;
	}
	.box_con .con>div{
		padding: 12px;
		border-bottom: 1px solid #EBEEF5;
	}
	.box_con .con>div:last-child{
		border-bottom: 0px solid;
	}
	.title{
		position: relative;
		background-color: #EBEEF5;
		font-size: 25px;
		padding: 15px;
		text-align: center;
		border-bottom: 0px solid;
		margin-bottom: 20px;
		font-weight: 600;
	}
	::-webkit-scrollbar {
		/*隐藏滚轮*/
		display: none;
	}
}

@media screen and (max-width: 999px){
	.log{
	  width: 100%;
	  height: 100%; 
	  position: relative;
	  overflow: auto;
	  box-sizing: border-box;
	  background-color: #fff;
	}
	
  .log-pagination{
	  position: fixed;
	  width: 100%;
	  bottom: 0;
      padding: 20px 0;
	  background-color: #fff;
	  overflow: auto;
	  z-index: 999;
	  border-top: 1px #EBEEF5 solid;
    }
	::v-deep .el-table .rowstyle{
		color:red;
		width: 100%;
	}
	::v-deep .el-table .rowstylerejected{
		width: 100%;
		color:#333fff;
	}
	::v-deep .el-table .el-table__row{
		width: 100%;
	}
	.box_box{
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 16%;
		margin-bottom: 70px;
		overflow: Scroll;
	}
	::-webkit-scrollbar {
		/*隐藏滚轮*/
		display: none;
	}
	.box{
		position: fixed;
		top: 0;
		z-index: 999;
		width: 100%;
		height: 100%;
		background-color:rgba(0,0,0,0.2);
		box-sizing: border-box;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #606662;
	}
	.box_con{
		background-color: #fff;
		margin: 0 auto;
		width: 90%;
		// position: relative;
	}
	.con_vo{
		overflow: auto;
		height: 50vh;
	}
	.box_con .con{
		padding: 0px 20px;
		border-bottom: 1px solid #EBEEF5;
	}
	.box_con .con>div{
		padding: 12px;
		border-bottom: 1px solid #EBEEF5;
	}
	.box_con .con>div:last-child{
		border-bottom: 0px solid;
	}
	
	.title{
		position: relative;
		background-color: #EBEEF5;
		font-size: 25px;
		padding: 15px;
		text-align: center;
		border-bottom: 0px solid;
		margin-bottom: 20px;
		font-weight: 600;
	}
	.LineFeed span{
		display: block;
		line-height: 25px;
	}
}
</style>

